body {
  margin: 0;
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-size: 16px;
  background-color: #f3f5f7;
}

.button--primary {
  width: 100px;
  background-color: #5581b3;
  padding-block: 5px;
  margin-inline: 10px;
  color: white;
  border-radius: 8px;
}

a {
  color: inherit;
  text-decoration: none;
}

.card--item:hover {
  cursor: pointer;
  filter: blur(8px);
}

.card--btn {
  display: none;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: aquamarine;
}

.card--item:hover + .card--btn,
.card--btn:hover {
  display: inline-block;
}

#input-file {
  display: none;
}

.label-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #2196f3;

  height: 56px;

  border: 1.5px dashed #3caef4;
  box-sizing: border-box;
  border-radius: 4px;
  color: #2196f3;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.H4 {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 123.5%;
  letter-spacing: 0.25px;
  color: #414749;
}

.H6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #414749;
}

.text--secondary {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: rgba(17, 51, 109, 0.86);
}

.overlay-css {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  cursor: pointer;
}

.error-message {
  color: red;
  font-size: 0.5rem;
}

.limit-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
